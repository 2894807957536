<template>
  <div>
    <a-modal
      width="1150px"
      v-model="isShow"
      centered
      :mask-closable="false"
      :loading="submitting"
      title="编辑套餐"
      @ok="handleSubmit"
    >
      <a-spin :spinning="spinning">
        <a-form
          class="iotplt-compact-form"
          :form="form"
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 15 }"
          @submit="handleSubmit"
        >
          <a-row>
            <!--基本信息-->
            <a-col :span="12">
              <span class="item-title">基本信息</span>
              <a-form-item label="套餐名称">
                <a-input
                  v-decorator="['name', {
                    normalize: this.$lodash.trim,
                    rules: [
                      { required: true, message: '请输入套餐名称' },
                      { max: 100, message: '最多100个字符' },
                    ]
                  }]"
                />
              </a-form-item>

              <a-form-item
                label="运营商种类"
                class="iotplt-display-item"
              >
                {{ data.carrier_type }}
              </a-form-item>

              <a-form-item
                label="用户业务模式"
                class="iotplt-display-item"
              >
                {{ data.user_business_type }}
              </a-form-item>

              <a-form-item
                label="套餐周期"
                class="iotplt-display-item"
              >
                {{ data.service_cycle }}
              </a-form-item>

              <a-form-item
                label="套餐容量"
                class="iotplt-display-item"
              >
                {{ data.package_capacity }}
              </a-form-item>

              <a-form-item
                label="语音"
                class="iotplt-display-item"
              >
                {{ data.voice_capacity }}
              </a-form-item>

              <a-form-item label="套餐描述">
                <a-textarea
                  v-decorator="['description', {
                    rules: [
                      { max: 200, message: '最多200个字符' },
                    ]
                  }]"
                />
              </a-form-item>
            </a-col>

            <!--价格设置-->
            <a-col :span="12">
              <span class="item-title">价格设置</span>

              <a-row
                :gutter="24"
                style="padding-left: 2px; margin-top: 10px; margin-bottom: 10px"
              >
                <a-col :span="12">周期数</a-col>
                <a-col :span="12">套餐价格(元)</a-col>
              </a-row>

              <a-row
                :gutter="24"
                v-for="item in agentsProductFavorablePrices"
                :key="item.id"
              >
                <a-col :span="12">
                  <a-form-item class="iotplt-display-item">
                    {{ item.cycles }}
                  </a-form-item>
                </a-col>

                <a-col :span="12">
                  <a-form-item class="iotplt-display-item">
                    <span :class="item.distributor_price === '待设置' ? 'pending-set' : ''">
                      {{ item.distributor_price | formatCurrency }}
                    </span>
                  </a-form-item>
                </a-col>
              </a-row>

              <a-row style="margin-top: 10px" :gutter="24" v-show="isShowExcessRateItem">
                <a-col :span="12">
                  超额计费单位
                </a-col>
                <a-col :span="12">
                  价格(元)
                </a-col>
              </a-row>

              <a-row style="margin-top: 10px" :gutter="24" v-show="isShowExcessRateItem">
                <a-col :span="12">
                  <a-form-item class="iotplt-display-item">
                    {{ data.excess_rate_quantity }}
                  </a-form-item>
                </a-col>

                <a-col :span="12">
                  <a-form-item class="iotplt-display-item">
                    <span :class="data.excess_distributor_price === '待设置' ? 'pending-set' : ''">
                      {{ data.excess_distributor_price | formatCurrency }}
                    </span>
                  </a-form-item>
                </a-col>
              </a-row>

              <a-row style="margin-top: 10px" :gutter="24" v-show="isShowStopItem">
                <a-col :span="24">
                  停机保号价格(元)
                </a-col>
              </a-row>

              <a-row style="margin-top: 10px" :gutter="24" v-show="isShowStopItem">
                <a-col :span="24">
                  <a-form-item class="iotplt-display-item">
                    <span :class="data.stop_distributor_price === '待设置' ? 'pending-set' : ''">
                      {{ data.stop_distributor_price | formatCurrency }}
                    </span>
                  </a-form-item>
                </a-col>
              </a-row>

              <span class="item-title">卡片设置</span>

              <a-row style="margin-top: 10px; margin-bottom: 10px" :gutter="24">
                <a-col :span="12">
                  卡片材质
                </a-col>
                <a-col :span="12">
                  卡板价格(元)
                </a-col>
              </a-row>

              <a-row
                :gutter="24"
                v-for="cardType in agentsProductsCardTypes"
                :key="cardType.id"
              >
                <a-col :span="12">
                  <a-form-item class="iotplt-display-item">
                    {{ cardType.card_type_name }}
                  </a-form-item>
                </a-col>

                <a-col :span="12">
                  <a-form-item class="iotplt-display-item">
                    <span :class="cardType.price === '待设置' ? 'pending-set' : ''">
                      {{ cardType.price | formatCurrency }}
                    </span>
                  </a-form-item>
                </a-col>
              </a-row>
            </a-col>
          </a-row>
        </a-form>
      </a-spin>
    </a-modal>
  </div>
</template>

<script>
import { findAgencyAgentsProductBySelfForm, updateAgencyAgentsProductBySelf } from '@/api/agents_product'

export default {
  name: 'EditAgentsProduct',
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      isShowExcessRateItem: true, // 是否显示设置超额参数一项
      isShowStopItem: false, // 是否显示设置停机保号参数一项
      data: {},
      agentsProductFavorablePrices: {
        type: Object
      },
      agentsProductsCardTypes: {},
      spinning: true,
      submitting: false,
      form: this.$form.createForm(this, { name: 'agents_product' })
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  created() {
    this.fetchFormData()
  },
  methods: {
    fetchFormData() {
      this.spinning = true
      findAgencyAgentsProductBySelfForm(this.id).then((res) => {
        if (res.code === 0) {
          this.data = res.data
          this.agentsProductFavorablePrices = res.data.agents_product_favorable_prices
          this.agentsProductsCardTypes = res.data.agents_products_card_types

          if (this.data.user_business_type_slug === 'custom_day') {
            this.isShowExcessRateItem = false
          }

          if (this.data.user_business_type_slug === 'custom_month' || this.data.user_business_type_slug === 'traffic_share') {
            this.isShowStopItem = true
          }

          this.form.setFieldsValue({
            name: this.data.name,
            description: this.data.description
          })
        }

        this.spinning = false
      })
    },

    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }

      this.form.validateFields((err, values) => {
        if (!err) {
          this.submitting = true
          updateAgencyAgentsProductBySelf(this.id, values).then((res) => {
            if (res.code === 0) {
              // 关闭模态框
              this.isShow = false
              // 告知父组件已完成
              this.$emit('completed')
            }
            this.submitting = false
          })
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
  .item-title {
    color: @primary-color;
  }

  .pending-set {
    color: red;
  }
</style>
